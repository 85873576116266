import { SvgIconProps } from '@/types/helpers'

export default function Share(props: SvgIconProps) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="#BCBFC2"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2816 9.13237L13.165 4.20204C12.5971 3.74422 11.625 4.1316 11.625 4.69507V6.8785C8.43568 6.98415 6.04369 7.82935 4.25243 9.27323C1.5 11.4919 1.5 14 1.5 15.2C4.25243 11.84 11.625 11.84 11.625 11.84V14.5205C11.625 15.084 12.6408 15.4361 13.165 15.0135L19.2816 10.0832C19.4563 9.94235 19.5 9.77608 19.5 9.6C19.5 9.42392 19.4563 9.27323 19.2816 9.13237Z"
        fill="currentFill"
      />
    </svg>
  )
}
