import { SvgIconProps } from '@/types/helpers'

export default function Heart(props: SvgIconProps) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="#BCBFC2"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6187 3.94706C16.7268 2.94916 15.541 2.40002 14.2794 2.40002C13.0178 2.40002 11.959 2.94916 11.0667 3.94706L10.5 4.85223L9.93333 3.94706C9.0413 2.94916 7.98219 2.40002 6.72076 2.40002C5.45938 2.40002 4.2731 2.94916 3.38115 3.94706C1.53962 6.00735 1.53962 9.35953 3.38115 11.419L9.97989 17.7609C10.0878 17.8821 10.2221 17.9563 10.3624 17.9848C10.4095 17.9952 10.4568 18 10.5042 18C10.6909 18 10.8779 17.9204 11.0201 17.7609L17.6188 11.419C19.4604 9.35953 19.4604 6.00735 17.6187 3.94706Z"
        fill="currentFill"
      />
    </svg>
  )
}
