'use client'

import ArrowForward from '@/app/_components/Icon/ArrowForward'
import Search from '@/app/_components/Icon/Search'
import { Button } from '@/app/_primitives/Button'
import { stringArray } from '@/app/utils/zod_stringArray'
import { useSearchParams } from '@/libraries/search-params'
import { useRouter } from 'next/navigation'
import { FormEventHandler, useCallback, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { z } from 'zod'

export default function SearchBar({
  variant = 'default',
  className,
  inSearchPage,
}: {
  variant?: 'default' | 'lg' | 'sm'
  className?: string
  inSearchPage?: boolean
}) {
  const [state, setState] = useSearchParams(
    z.object({
      keyword: z.string().default(''),
      keywordHistory: stringArray(),
    }),
  )

  const [keyword, setKeyword] = useState(state.keyword)

  const router = useRouter()
  const handleSubmit: FormEventHandler = useCallback(
    (e) => {
      e.preventDefault()
      if (inSearchPage) {
        const history = new Set(state.keywordHistory)

        if (keyword.trim() !== '') {
          history.add(keyword)
        }

        setState({
          keyword,
          keywordHistory: Array.from(history).reverse().slice(0, 10),
        })
      } else
        router.push(
          `/search/characters?keyword=${keyword}&keywordHistory=${keyword}`,
        )
    },
    [inSearchPage, router, keyword, state.keywordHistory, setState],
  )

  const inputCName = twMerge(
    `c-b4 lg:c-b3 lg:min-w-445 w-full rounded-100 bg-white pl-62 pr-52 outline-none`,
    variant === 'lg' ? 'h-72' : variant === 'sm' ? 'h-48' : 'h-56',
  )

  return (
    <form className={twMerge('relative', className)} onSubmit={handleSubmit}>
      <input
        className={inputCName}
        type="search"
        name="search"
        placeholder="동물 캐릭터"
        value={keyword}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSubmit(e)
          }
        }}
        onChange={(e) => setKeyword(e.target.value)}
      />
      <div className="absolute left-30 top-0 flex h-full items-center">
        <Search />
      </div>
      <div className="absolute right-0 top-0 ">
        <Button
          type="submit"
          color="butterScotch"
          size={variant}
          className={`flex w-136 items-center justify-center lg:w-166`}>
          Search
          <ArrowForward />
        </Button>
      </div>
      {/* <button
        type="submit"
        className={twMerge(
          `absolute right-0 top-0 flex h-full items-center justify-center rounded-100 bg-butterscotch p-10 w-136 lg:w-166`,
        )}>
        <div className="flex items-center gap-7">
          <span className={twMerge(`c-h7 lg:c-h6`)}>Search</span>
          <ArrowForward />
        </div>
      </button> */}
    </form>
  )
}
