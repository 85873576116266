import { z } from 'zod'

export function getDefaults<Schema extends z.AnyZodObject>(
  schema: Schema,
): z.TypeOf<Schema> {
  return Object.fromEntries(
    Object.entries(schema.shape).map(([key, value]) => {
      // eslint-disable-next-line no-underscore-dangle
      if (value instanceof z.ZodDefault) return [key, value._def.defaultValue()]
      return [key, undefined]
    }),
  )
}
