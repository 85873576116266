import { z } from 'zod'

export const stringArray = (validValues: string[] = []) =>
  z
    .preprocess((value) => {
      if (value === undefined) return []
      if (typeof value === 'string') {
        return value
          .split(',')
          .filter((v) => v !== '')
          .filter((v) => validValues.length === 0 || validValues.includes(v))
      }
      return value
    }, z.array(z.string()))
    .default([])
