'use client'

import { useI18n } from '@/locales/client'

export default function TotalCount({ count }: { count: string }) {
  const t = useI18n()
  return (
    <>
      {count} {t('common.mainCharCount')}
    </>
  )
}
