'use client'

import { getDefaults } from '@/libraries/zod/getDefaults'
import { NavigateOptions } from 'next/dist/shared/lib/app-router-context.shared-runtime'
import {
  useSearchParams as next_useSearchParams,
  useRouter,
} from 'next/navigation'
import { useMemo } from 'react'
import { z } from 'zod'

export function useSearchParams<Schema extends z.AnyZodObject>(
  schema: Schema,
  routeTransitionType: 'push' | 'replace' = 'replace',
  navigationOptions?: NavigateOptions,
) {
  const searchParams = next_useSearchParams()
  const router = useRouter()

  const values: z.TypeOf<Schema> = useMemo(() => {
    const params = Object.fromEntries(searchParams.entries())
    const parsed = schema.safeParse(params)

    if (!parsed.success) {
      return getDefaults(schema)
    }
    return parsed.data
  }, [schema, searchParams])

  const setter = (value: Partial<z.TypeOf<Schema>>) => {
    const updated = {
      ...Object.fromEntries(searchParams.entries()),
      ...value,
    }

    const currentURL = new URL(window.location.href)
    Object.keys(updated).forEach((key) => {
      currentURL.searchParams.set(key, updated[key])
    })

    router[routeTransitionType](
      `${window.location.pathname}?${currentURL.searchParams.toString()}`,
      navigationOptions,
    )
  }

  return [values, setter] as const
}
