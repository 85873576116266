'use client'

import Heart from '@/app/_components/Icon/Heart'
import Share from '@/app/_components/Icon/Share'
import Verified from '@/app/_components/Icon/Verified'
import { LikeButton } from '@/app/_components/Shared/LikeButton'
import { toast } from '@/app/_primitives/Toast'
import { CharacterItem } from '@/backend/models/Character'
import { getUserAvatarURL } from '@/libraries/avatar'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/navigation'
import { twMerge } from 'tailwind-merge'

type Props = {
  character: CharacterItem
  className?: string
}

export default function CharacterCard({ className = '', character }: Props) {
  const router = useRouter()
  return (
    <Link
      data-id={character.id}
      href={`/characters/${character.humanReadableID}`}
      className={twMerge(
        'relative max-lg:w-full w-300 h-496 hover:-translate-y-10 hover:shadow-xl transition-transform duration-300 ease-in-out flex flex-col rounded-20 overflow-hidden box-border shadow-card-mobile xl:shadow-card-web bg-transparent',
        className,
      )}>
      <div
        className="relative h-275 w-full shrink-0 rounded-t-22 "
        style={{ backgroundColor: character.colorSpace || '#FFFFFF' }}>
        <Image
          className="object-cover"
          src={
            character.imageURL === 'need-image'
              ? 'https://picsum.photos/300/275'
              : character.imageURL
          }
          fill
          alt={`${character.name}`}
        />
      </div>
      <div className="h-221 bg-white p-24">
        <div className="flex h-full flex-col justify-between">
          <div className="flex flex-col gap-14">
            <div className="c-h6 line-clamp-1" title={character.name}>
              {character.name}
            </div>
            <div className="c-b4 line-clamp-3">
              {character.oneLineIntroduction}
            </div>
          </div>
          <div className="flex justify-between">
            <button
              type={'button'}
              onClick={(e) => {
                e.preventDefault()
                router.push(`/u/${character.user.id}`)
              }}
              className="c-b4 flex items-center gap-8 text-[#7C7C7C]">
              <div className="relative size-29 shrink-0 overflow-hidden rounded-full">
                <Image
                  src={
                    character.user.imageURL ||
                    getUserAvatarURL(character.user.id)
                  }
                  fill
                  alt="category"
                  className="object-cover"
                />
              </div>
              <div className="line-clamp-1 max-w-95">
                {character.user.displayName}
              </div>
              {character.user.isVerified && <Verified fill="#3366B6" />}
            </button>
            <div className="flex gap-8">
              <LikeButton
                id={character.id}
                className="flex size-40 items-center justify-center rounded-full border border-solid border-tertiary">
                <Heart className={character.isLiked ? 'fill-cherry' : ''} />
              </LikeButton>
              <div
                aria-hidden
                onClick={(e) => {
                  e.preventDefault()
                  navigator.clipboard.writeText(
                    `${document.location.host}/characters/${character.id}`,
                  )
                  toast.success({
                    title: `${character.name} URL을 복사했습니다.`,
                    content: '원하는 곳에 붙여넣기(Ctrl+V) 해주세요.',
                  })
                }}
                className="flex size-40 cursor-pointer items-center justify-center rounded-full border-1 border-tertiary">
                <Share />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}
